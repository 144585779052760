import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Libur: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Libur,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Libur"
          d="M874,1012l39,44.6c-11.9,6.5-6.8,13.4-23.8,21.1c-0.2,0.1-11.6,7-12.1,7.3c-5.7,1.6-18.7,4.7-24.6,4.4 c-12.7-1.1-26.4-0.9-38.9,1.4c-1-8.1-1-26.5-1.1-34.6c0.3-0.1,1.7-0.1,2.1-0.1c0.1-2.8-0.6-8.1-1.4-10.8c0,0-1.1-11.4-1.1-11.4 c-3.5-6.8-8.5-14.3-9.7-22H800l40-73.9C850.8,983.3,843.2,975.4,874,1012z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 837.2557 1041.1895)"
        >
          Libur
        </text>
      </g>
    </>
  );
});
